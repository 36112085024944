* {
  padding: 0;
  /* margin: 0; */
  /* font-family: "Roboto Bold"; */
}
@font-face {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Bold"), url("./assets/font/Roboto-Bold.ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto"), url("./assets/font/Roboto-Regular.ttf");
}
@font-face {
  font-family: "Bebas Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Bebas Bold"), url("./assets/font/SVN-Bebas\ Neue\ Bold.otf");
}
