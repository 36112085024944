.login {
  padding: 20px;
  background-color: antiquewhite;
  height: 100vh;
  .form {
    width: 520px;
    background-color: white;
    padding: 30px 50px;
    border-radius: 16px;
    margin: auto;
    margin-top: 100px;
    h2 {
      text-align: center;
    }
    .text_input {
      border-radius: 6px;
    }
  }
  .header {
    text-align: end;
    .home {
      cursor: pointer;
    }
  }
  .action {
    text-align: center;
    .btnLogin {
      width: 80%;
      border-radius: 6px;
    }
  }
}
@media screen and (max-width: 767px) {
  .login {
    padding: 0;
    .header {
      padding: 20px;
    }
    .form {
      width: 100%;
      padding: 30px;
    }
  }
}
