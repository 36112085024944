.ant-table-pagination.ant-pagination {
  display: none;
}
th,
td {
  text-align: center !important;
}
.title {
  font-family: "Bebas Bold";
  text-transform: uppercase;
  font-size: 30px;
}
.text {
  font-family: "Roboto";
  // padding: 0 30px;
}
.btn-transform-gift {
  background-color: #4ebc29;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  font-size: 25px;
  font-family: "Bebas Bold";
  color: white;
  margin: 0 20px;
}
.inputPhoneNumber {
  font-family: "Bebas Bold";
  font-size: 18px !important;
}
.input-btn {
  display: flex;
  .btn {
    margin-left: 20px;
    font-family: "Bebas Bold";
  }
}
@media screen and(max-width: 600px) {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 12px !important;
  }
}
