@font-face {
  font-family: "Roboto Bold";
  src: url("../../assets/font/Roboto-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../../assets/font/Roboto-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Bebas Bold";
  src: url("../../assets/font/SVN-Bebas Neue Bold.otf");
  font-weight: normal;
  font-style: normal;
}
img {
  max-width: 100%;
}
a:hover,
a:focus,
a:active,
a:visited {
  text-decoration: none;
}
#title-scan {
  font-family: "Bebas Bold";
  font-size: 58px;
  text-transform: uppercase;
  color: #ffe641;
  margin: 0;
  margin-bottom: 40px;
}
#title-result {
  font-family: "Bebas Bold";
  font-size: 45px;
  text-transform: uppercase;
  color: #ffe641;
  margin: 0;
  margin-bottom: 40px;
  border-top: 4px solid #ffe641;
  padding-top: 30px;
}
#select {
  text-align: center;
}
#select-year {
  text-align: center;
  padding-bottom: 30px;
}
#btn-connect-gif {
  font-family: "Bebas Bold";
  font-size: 30px;
  color: #ffe641;
  padding: 10px 30px;
  border: solid 1px #ffe641;
  max-width: 250px;
  text-align: center;
  letter-spacing: 1px;
  margin: auto;
  background: #041e33;
  margin-bottom: 30px;
}
.nt-bxh {
  background: #041e33;
}
.nt-bxh .bxh-wrap {
  padding-top: 30px;
}
.nt-bxh .bxh-wrap p {
  text-align: center;
  color: #ffe641;
}
.nt-bxh .bxh-wrap .bxh-lead {
  text-align: center;
}
.nt-bxh .bxh-wrap .bxh-lead img {
  max-width: 250px;
  width: 100%;
}
.nt-bxh .bxh-wrap .bxh-title {
  float: left;
  width: 100%;
  margin-top: 5px;
  text-align: center;
  margin-bottom: 30px;
}
.nt-bxh .bxh-wrap .bxh-title span {
  font-family: Roboto Bold;
  font-size: 30px;
  text-transform: uppercase;
  color: #ffe641;
  padding-bottom: 9px;
}
.nt-bxh .bxh-wrap .top-client {
  /* background-image: url("../../assets/images/nt-quoc-anh.JPG"); */
  /* background-size: cover; */
  /* background-position: 10% 10%; */
  text-align: center;
  height: 400px;
  position: relative;
  float: left;
  width: 100%;
  margin-top: 30px;
}
#img-response {
  width: 100%;
}
#top-client {
  /* background-image: url("../../assets/images/nt-quoc-anh.JPG"); */
  background-size: cover 100%;
}
.nt-bxh .bxh-wrap .top-client .color {
  background-color: rgba(4, 30, 51, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.nt-bxh .bxh-wrap .top-client .color img {
  max-width: 80px;
  margin-bottom: 10px;
}
.nt-bxh .bxh-wrap .top-client .color .top-title {
  font-family: "Bebas Bold";
  font-size: 58px;
  text-transform: uppercase;
  color: #ffe641;
  margin: 0;
}
.nt-bxh .bxh-wrap .top-client .color .top-add {
  font-family: "Roboto Bold";
  font-size: 30px;
  color: white;
}
.nt-bxh .bxh-wrap .client {
  float: left;
  width: 100%;
  background: white;
  padding: 30px 0;
  color: #041e33;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}
.nt-bxh .bxh-wrap .client .client-no {
  font-family: "Roboto Bold";
  font-size: 50px;
  padding-top: 10px;
  text-align: center;
}
.nt-bxh .bxh-wrap .client .client-info {
  text-align: left;
  padding-left: 30px;
}
.nt-bxh .bxh-wrap .client .client-info p {
  color: #041e33;
  text-align: left;
  padding-left: 30px;
}
.nt-bxh .bxh-wrap .client .client-info .client-title {
  font-family: "Bebas Bold";
  font-size: 40px;
  margin-bottom: 10px;
  margin-top: 0;
}
.nt-bxh .bxh-wrap .client .client-info .client-add {
  font-family: "Roboto";
  font-size: 18px;
}
.nt-bxh .single-client {
  margin-top: 100px;
  text-align: center;
}
.nt-bxh .single-client .button-qr {
  font-family: "Bebas Bold";
  font-size: 40px;
  color: #041e33;
  background: #ffe641;
  padding: 10px 0;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
}
.nt-bxh .single-client .button-qr svg {
  margin-left: 10px;
}
.nt-bxh .single-client .single-info {
  margin-top: 50px;
  float: left;
  width: 100%;
  /* background: white; */
  /* padding-bottom: 40px; */
}
.nt-bxh .single-client .single-info img {
  width: 100%;
  max-height: 650px;
}
.nt-bxh .single-client .single-info .single-title {
  font-family: "Bebas Bold";
  font-size: 50px;
  padding-top: 40px;
  margin-bottom: 0;
}
.nt-bxh .single-client .single-info .single-add {
  font-family: "Roboto Bold";
  font-size: 20px;
}
.nt-bxh .single-client .single-info .single-point {
  float: left;
  width: 50%;
  /* margin-top: 40px; */
}
.nt-bxh .single-client .single-info .single-point p {
  font-family: "Roboto Bold";
  font-size: 30px;
  text-transform: uppercase;
}
.nt-bxh .single-client .single-info .single-point .point {
  font-family: "Bebas Bold";
  font-size: 80px;
  /* margin: 30px 0; */
}
.nt-bxh .list {
  padding-top: 40px;
}
.nt-bxh .list .list-lead {
  font-family: "Bebas Bold";
  font-size: 40px;
  color: #ffe641;
  text-align: center;
  margin-bottom: 70px;
}
.nt-bxh .list .prod {
  float: left;
  width: 100%;
}
.nt-bxh .list .prod .prod-img {
  width: 33%;
  float: left;
  margin-left: 5%;
}
.nt-bxh .list .prod .prod-info {
  float: left;
  margin-left: 8%;
  width: 54%;
  padding-top: 30px;
}
.nt-bxh .list .prod .prod-info img {
  max-width: 200px;
  margin-bottom: 15px;
}
.nt-bxh .list .prod .prod-info .prod-sub {
  font-size: 23px;
  font-family: "Roboto";
  color: white;
}
.nt-bxh .list .prod .prod-info .des {
  margin-top: 10px;
  font-family: Roboto;
  font-size: 16px;
  color: white;
}
.nt-bxh .list .prod .prod-info .prod-point {
  font-family: "Bebas Bold";
  font-size: 30px;
  color: #ffe641;
  padding: 10px 30px;
  margin-top: 30px;
  border: solid 1px #ffe641;
  max-width: 250px;
  text-align: center;
  letter-spacing: 1px;
}

.nt-bxh .tutorial {
  margin-top: 50px;
  background: white;
  padding-top: 30px;
  padding-bottom: 30px;
}
.nt-bxh .tutorial .tut-lead {
  font-family: "Bebas Bold";
  font-size: 40px;
  color: #041e33;
  text-align: center;
}
.nt-bxh .tutorial .tut-text {
  padding: 0 30px 20px;
}
.nt-bxh .tutorial .tut-text p {
  font-family: Roboto;
  font-size: 15px;
}
.nt-bxh .tutorial .tut-text .tut-title {
  font-family: "Bebas Bold";
  font-size: 30px;
  margin-bottom: 5px;
}
.nt-bxh .tutorial .tut-text a {
  font-family: "Roboto Bold";
  color: #041e33;
}
.footer {
  background: #041e33;
  /* padding-top: 50px; */
}
.footer .footer-wrap {
  padding-bottom: 30px;
  /* border-top: solid 1px #ffe641; */
  /* padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 50px; */
}
.footer .footer-wrap img {
  max-width: 200px;
}
.footer .footer-wrap .slogan {
  font-family: "Roboto Bold";
  font-size: 20px;
  color: #dabc3d;
}
.footer .footer-wrap p {
  font-family: Roboto;
  color: white;
  font-size: 17px;

  margin-bottom: 0;
}
.footer .footer-wrap p a {
  font-family: "Roboto Bold";
  color: white;
}
#transformGift {
  color: rgb(4, 30, 51);
  font-family: "Bebas Bold";
  font-size: 22px;
  height: 50px;
  width: 18%;
}
#img-tinh img {
  padding-top: 40px;
}
#tranfrom button {
  width: 25%;
  background-color: #041e33;
  height: 60px;
  border: #ffe641 1px solid;
}
#transformGift {
  color: #ffe641;
}
.nt-bxh .list .prod .prod-img {
  padding: 20px 0;
}
@media only screen and (max-width: 991px) {
  .nt-bxh .bxh-wrap .client .client-info {
    padding-top: 4%;
  }
  .nt-bxh .bxh-wrap .client .client-no {
    padding-top: 6%;
  }
}
@media only screen and (max-width: 767px) {
  #tranfrom button {
    width: 100% !important;
  }
  .nt-bxh .single-client .single-info img {
    width: 100%;
    max-height: 380px !important;
  }
  .nt-bxh .bxh-wrap .client .client-info .client-title {
    margin-top: auto;
    margin-bottom: auto;
  }
  #single-point {
    height: 150px;
  }
  .col-xs-4.client-img {
    width: 35%;
  }
  #title-scan {
    font-size: 37px;
  }
  .nt-bxh .list .prod .prod-info {
    padding-top: 0;
  }
  .nt-bxh .list .list-lead {
    margin-bottom: 40px;
  }
  .nt-bxh .list .prod .prod-info img {
    margin-bottom: 0;
  }
  .nt-bxh .bxh-wrap .top-client .color img {
    margin-top: 10px !important;
  }
  .nt-bxh .bxh-wrap .top-client .color .top-title {
    font-size: 36px;
  }
  .nt-bxh .bxh-wrap .top-client .color .top-add {
    font-size: 19px;
  }
  .nt-bxh .bxh-wrap .top-client {
    height: 300px;
  }
  .nt-bxh .bxh-wrap .client .client-no {
    font-size: 30px;
    padding-top: 8px;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .nt-bxh .bxh-wrap .client .client-info {
    padding-top: 0;
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 10px;
  }
  .nt-bxh .bxh-wrap .client .client-info .client-title {
    padding: 0;
    font-size: 19px;
    margin-bottom: 5px;
    padding-right: 20px;
  }
  .nt-bxh .bxh-wrap .client .client-info .client-add {
    padding: 0;
    font-size: 15px;
    margin-top: 0;
  }
  .nt-bxh .single-client .button-qr {
    width: 80%;
    font-size: 23px;
  }
  .nt-bxh .single-client .single-info .single-title {
    font-size: 30px;
    padding-top: 25px;
    margin-top: 0;
  }
  .nt-bxh .single-client .single-info .single-add {
    font-size: 16px;
  }

  .nt-bxh .single-client .single-info .single-point p {
    font-size: 18px;
  }
  .nt-bxh .single-client .single-info .single-point .point {
    font-size: 60px;
    margin: 30px 0 50px 0;
  }
  .nt-bxh .list .prod .prod-img {
    width: 100%;
    margin-left: 0;
    text-align: center;
  }
  .nt-bxh .list .prod .prod-img img {
    width: 80%;
  }
  .nt-bxh .list .prod .prod-info {
    width: 100%;
    margin-left: 0;
    text-align: center;
  }
  .nt-bxh .list .prod .prod-info .prod-point {
    margin: 30px auto 30px;
  }
}
@media only screen and (max-width: 400px) {
}

/*# sourceMappingURL=nhathuoc.css.map */
