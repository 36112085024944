.history_point {
  .title {
    margin-bottom: 20px;
  }
  .table_point {
    table {
      border-collapse: collapse;
      width: 100%;
    }
    tr {
      text-align: center;
    }
    th,
    td {
      padding: 8px;
      border: 1px solid #dddddd;
    }
  }
  .pagination {
    padding-top: 10px;
  }
}
