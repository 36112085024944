@font-face {
  font-family: "Bebas Bold";
  src: url("../../assets/font/SVN-Bebas Neue Bold.otf");
  font-weight: normal;
  font-style: normal;
}
.img_qrcode {
  button {
    border: solid 1px #3f9e1f;
  }
}
.btn_img_qrcode {
  span {
    font-family: "Bebas Bold";
    color: white;
  }
  font-size: 33px;
  color: #041e33;
  background: #3f9e1f;
  padding: 10px 0;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
}
@media only screen and (max-width: 767px) {
  .img_qrcode .btn_img_qrcode {
    width: 100% !important;
  }
}
