.lstItem-info {
  text-align: center;
  .lstItem-point {
    font-family: "Bebas Bold";
    font-size: 30px;
    color: #ffe641;
    padding: 10px 30px;
    margin: 30px auto;
    border: solid 1px #ffe641;
    max-width: 250px;
    text-align: center;
    letter-spacing: 1px;
  }
  .lstItem-sub {
    font-size: 18px;
  }
}
.lstItem {
  background: #041e33;
  color: white;
  font-family: Roboto;
  font-size: 15px;
  .lstItem-img {
    display: flex;
    justify-content: center;
  }
}
